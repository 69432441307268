import {Button, Dropdown, MenuProps, message, Popconfirm, Space, Table, Tooltip, Typography} from "antd";
import {MenuItemType} from "antd/es/menu/interface";
import * as _ from "lodash";
import {Folder} from "../../../domain/Folder";
import {File} from "../../../domain/File";
import {useIntlMessage} from "../../../sal-ui/createIntlMessage";
import styles from "./PackageDownload.module.css";
import FormatUtils from "../../../utils/FormatUtils";
import {GetComponentProps} from "rc-table/lib/interface";
import {DetectionEngineType, DownloadCounter, FileCheck, FileFlag, FileIntegrityResult, FinalFileCheckResult, Package, PackageWorkflowState} from "../../../domain/Package";
import {
    BarsOutlined,
    CheckCircleFilled,
    ColumnHeightOutlined,
    DeleteFilled,
    DeliveredProcedureOutlined,
    DownloadOutlined,
    EditOutlined,
    ExclamationCircleFilled,
    FilePdfFilled,
    FileUnknownFilled,
    FileZipOutlined,
    FolderOutlined,
    LinkOutlined,
    QuestionCircleFilled,
    SecurityScanFilled,
    SyncOutlined
} from "@ant-design/icons";
import {DownloadObjectType} from "../../../service/PackageService";
import {ColumnGroupType, ColumnType} from "antd/es/table/interface";
import {SortOrder} from "antd/lib/table/interface";
import folderContentStyles from "./FolderContent.module.css";
import {Link} from "react-router-dom";
import OkLeftPopconfirm from "../../OkLeftPopconfirm";
import CopyToClipboard from "react-copy-to-clipboard";
import {User} from "../../../domain/User";
import Column from "antd/lib/table/Column";
import React, {useEffect, useRef, useState} from "react";
import CheckboxPopConfirm from "../../common/CheckboxPopConfirm";
import {FileOrFolderAction} from "../../../domain/FileOrFolderAction";

const {Text} = Typography;

export enum MassActions {
    MOVE = "MOVE",
    DOWNLOAD_AS_ZIP = "DOWNLOAD_AS_ZIP",
    DELETE = "DELETE",
}

export enum ColumnName {
    SELECT = "SELECT",
    NAME = "NAME",
    PATH = "PATH",
    STATE = "STATE",
    HASH = "HASH",
    MIME_TYPE = "MIME_TYPE",
    UPLOADED_BY = "UPLOADED_BY",
    UPLOADED = "UPLOADED",
    DELETED = "DELETED",
    SIZE = "SIZE",
    DOWNLOAD_COUNTER = "DOWNLOAD_COUNTER",
    ACTION = "ACTION"
}

interface Props {
    packageId: string;
    aPackage?: Package;
    items: (File | Folder)[];
    visualizeState?: boolean;
    disableDownloadLinks?: boolean;
    disableFolderNameLinks?: boolean;
    visibleColumns: ColumnName[];
    integrityCheckAllowed: boolean;
    isFileDownloadAllowed?: (file: File) => boolean;
    onRowFileCheck?: GetComponentProps<FileCheck>;
    onClickDownload?: (objectPath: string, objectId: string, objectType: DownloadObjectType) => void;
    onClickFileCheck?: (fileCheck: FileCheck) => void;
    onClickExtraFileCheckInfo?: (extraInfo: any) => void;
    onEditFolder?: (folder: Folder) => void;
    onDeleteFolderConfirm?: (folder: Folder) => void;
    onEditFile?: (file: File) => void;
    onDeleteFileConfirm?: (file: File, deleteSafeCopy: boolean) => void;
    onRunIntegrityCheckOnFile?: (file: File) => void;
    onFolderNameClick?: (folder: Folder) => void;
    loading?: boolean;
    hideFileFlags?: boolean;
    hideExpandable?: boolean;
    maxHeight?: number | string;

    // resetSelected?: string;

    massActions?: MassActions[];
    onMove?: (items: (File | Folder)[]) => Promise<void>;
    onDownloadAsZip?: (items: (File | Folder)[]) => Promise<void>;
    onMassDeleteConfirm?: (files: (File | Folder)[], deleteSafeCopy: boolean) => Promise<void>;

}

type ColumnMap = {
    [key in ColumnName]: (ColumnGroupType<File | Folder> | ColumnType<File | Folder>);
};

function FolderContent(props: Props) {
    const intlMessage = useIntlMessage("package-detail");

    const [actionableItems, setActionableItems] = useState<(File | Folder)[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<(File | Folder)[]>([]);
    const [possibleActions, setPossibleActions] = useState<FileOrFolderAction[]>([]);
    const onSelectTimer = useRef<any>();

    useEffect(() => {
        setSelectedRowKeys([]);

        const actions = props.massActions?.map(value => {
            switch (value) {
                case MassActions.MOVE:
                    return FileOrFolderAction.RENAME_OR_MOVE;
                case MassActions.DOWNLOAD_AS_ZIP:
                    return FileOrFolderAction.DOWNLOAD;
                case MassActions.DELETE:
                    return FileOrFolderAction.DELETE;
            }
        });

        setPossibleActions(actions || []);

        setActionableItems(
            props.items.filter((fileOrFolder) => _.intersection(actions, fileOrFolder.possibleActions).length !== 0)
        );
    }, [props.items, props.massActions]);

    const columnMap: ColumnMap = {
        SELECT: {
            dataIndex: "select",
            title: <input type={"checkbox"} onChange={onSelectAll} checked={(!_.isEmpty(actionableItems) && actionableItems.every((value: (File | Folder)) => selectedRowKeys.includes(value!))) || false}/>,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            width: 45,
            render: (value, record: (File | Folder)) => {
                if (actionableItems.includes(record)) {
                    return <input type={"checkbox"} checked={selectedRowKeys.includes(record!)} onChange={() => onSelect(record!)}/>;
                }
            },
        },
        NAME: {
            dataIndex: "name",
            title: intlMessage("package-detail.file.name"),
            sorter: (a, b, sortOrder) => folderSorter(a, b, 'name', sortOrder),
            sortDirections: ["descend", "ascend", "descend"],
            defaultSortOrder: "ascend",
            render: renderItemName,
            className: "text-overflow max-width-300px"
        },
        PATH: {
            dataIndex: ["folder", "path"],
            title: intlMessage("package-detail.file.path"),
            sorter: (a, b, sortOrder) => folderSorter(a, b, 'folder.path', sortOrder),
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderPath,
            className: "text-overflow max-width-300px"
        },
        STATE: {
            dataIndex: "state",
            title: intlMessage("package-detail.file.state"),
            width: 90,
            render: renderState
        },
        HASH: {
            dataIndex: "hash",
            title: intlMessage("package-detail.file.integrity"),
            render: renderFileIntegrity,
            width: 150
        },
        MIME_TYPE: {
            dataIndex: "mimeType",
            title: intlMessage("package-detail.file.mimeType"),
            className: "text-overflow max-width-200px",
            sorter: (a, b, sortOrder) => folderSorter(a, b, 'mimeType', sortOrder),
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderFileMimeType
        },
        UPLOADED_BY: {
            dataIndex: "uploadedBy",
            title: intlMessage("package-detail.file.uploaded-by"),
            width: 200,
            render: renderUploadedBy
        },
        UPLOADED: {
            dataIndex: "uploaded",
            title: intlMessage("package-detail.file.uploaded"),
            render: renderUploadedOrCreated,
            width: 150,
            sorter: (a, b, sortOrder) => folderSorter(a, b, 'uploaded', sortOrder),
            sortDirections: ["descend", "ascend", "descend"]
        },
        DELETED: {
            dataIndex: "deleted",
            title: intlMessage("package-detail.file.deleted"),
            render: value => FormatUtils.formatDate(value),
            width: 150,
            sorter: (a, b, sortOrder) => folderSorter(a, b, 'deleted', sortOrder),
            sortDirections: ["descend", "ascend", "descend"]
        },
        SIZE: {
            dataIndex: "size",
            title: intlMessage("package-detail.file.size"),
            width: 90,
            sorter: (a, b, sortOrder) => folderSorter(a, b, 'size', sortOrder),
            sortDirections: ["descend", "ascend", "descend"],
            render: renderSize
        },
        DOWNLOAD_COUNTER: {
            dataIndex: "downloadCounter",
            title: intlMessage("package-detail.download-count"),
            sorter: packageFileDownloadCountSorter,
            sortDirections: ["descend", "ascend", "descend"],
            align: "center",
            render: renderDownloadCounter,
            width: 150
        },
        ACTION: {
            title: intlMessage("common.action"),
            render: renderItemActions,
            width: 70
        }
    }

    const columns = props.visibleColumns.map(value => columnMap[value]);

    const renderRowClass = (fileOrFolder: File | Folder): string => {
        if (fileOrFolder instanceof Folder) {
            return "info";
        }

        switch (fileOrFolder.state) {
            case "CLEAN":
                return "info";
            case "UNCLEAN":
                return "danger";
            default:
                return "warning";
        }
    };

    return (
        <>
            {renderMassActions()}

            <Table
                dataSource={props.items}
                size="middle"
                showSorterTooltip={false}
                rowKey="id"
                scroll={props.maxHeight ? {y: props.maxHeight} : {}}
                pagination={false}
                className={"package-detail-file-list"}
                expandable={
                    (props.hideExpandable)
                        ? undefined
                        : {
                            expandedRowRender: renderFileChecks,
                            expandIcon: renderTableExpandIcon
                        }}
                rowClassName={renderRowClass}
                loading={props.loading}
                columns={columns}
            />
        </>
    );

    function renderTableExpandIcon(data: any) {
        const expanded = data.expanded;
        const onExpand = data.onExpand;
        const fileOrFolder = data.record;

        if (fileOrFolder instanceof Folder) {
            return <FolderOutlined/>;
        }

        if ((!fileOrFolder.fileChecks || fileOrFolder.fileChecks.length == 0)) {
            const filePath = `/download-file/${fileOrFolder.id}`;

            if (fileOrFolder.possibleActions?.includes(FileOrFolderAction.DOWNLOAD)) {
                return <a href={filePath} onClick={e => {
                    e.preventDefault();

                    props.onClickDownload?.(filePath, fileOrFolder.id!, DownloadObjectType.FILE);
                }}>
                    <DownloadOutlined/>
                </a>;
            } else {
                return <></>;
            }
        }

        return expanded ? (
            <button type="button" onClick={e => onExpand(fileOrFolder, e)} className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded" aria-label="Zabalit řádek" aria-expanded="true"></button>
        ) : (
            <button type="button" onClick={e => onExpand(fileOrFolder, e)} className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed" aria-label="Rozbalit řádek" aria-expanded="false"></button>
        )
    }

    function renderPath(path: string, fileOrFolder: File | Folder) {
        const folder = (fileOrFolder instanceof Folder) ? fileOrFolder.parent : fileOrFolder.folder;

        if (folder?.name === '/') {
            return <Link to={`/packages/${props.packageId}`} target={"_blank"}>{intlMessage("root-folder")}</Link>;
        }

        return <Link to={`/packages/${props.packageId}/${folder?.id}`} target={"_blank"}>{(fileOrFolder instanceof Folder) ? fileOrFolder.path : path}</Link>;
    }

    function visualizeStateIfNeeded(fileOrFolder: File | Folder, content: React.ReactNode) {
        if (!props.visualizeState) {
            return content;
        }

        if (fileOrFolder.deleted) {
            return (
                <span className={folderContentStyles['state-container']}>
                    <span className={folderContentStyles['state-deleted']}><i>({intlMessage("package-detail.folder-status-deleted")})</i> &nbsp; {content}</span>
                </span>
            );
        }

        return content;
    }

    function renderFileChecks(record: File | Folder) {
        if (record instanceof Folder) {
            return;
        }

        const renderRowClass = (check: any): string => {
            switch (check.finalCheckResult) {
                case "CLEAN":
                case "NONE":
                    return "info";

                case "WARNING":
                    return "warning";

                case "UNCLEAN":
                default:
                    return "danger";
            }
        };

        return (
            <>
                {record.fileChecks && record.fileChecks.length > 0 &&
                    <>
                        <h3 className={styles["file-detail-title"]}>{intlMessage("package-detail.file-check.results")}</h3>

                        <Table dataSource={record.fileChecks}
                               rowKey="id"
                               pagination={false} size="small"
                               showSorterTooltip={false}
                               rowClassName={renderRowClass}
                               onRow={props.onRowFileCheck}>
                            <Column dataIndex="checkStarted"
                                    title={intlMessage("package-detail.file-check.check-started")}
                                    render={date => FormatUtils.formatDate(date)} width="14em"/>
                            <Column dataIndex="checkFinished"
                                    title={intlMessage("package-detail.file-check.check-finished")}
                                    render={date => FormatUtils.formatDate(date)} width="14em"/>
                            <Column dataIndex={["detectionEngine", "type"]}
                                    title={intlMessage("package-detail.file-check.engine-type")}
                                    render={t => intlMessage('detection-engines.type-' + t)}/>
                            <Column dataIndex="checkResult"
                                    title={intlMessage("package-detail.file-check.check-result")}
                                    render={renderCheckResult}/>
                            <Column dataIndex="detectionName"
                                    title={intlMessage("package-detail.file-check.detection-name")}
                                    render={renderDetectionName} className={"text-overflow max-width-200px"}/>
                            <Column render={renderExtraFileCheckInfo}/>
                        </Table>
                    </>
                }
            </>
        )
    }

    function renderCheckResult(text: any, fileCheck: FileCheck) {
        if (text !== 'NONE') {
            return intlMessage('package-detail.file-check.result-type-' + text + (fileCheck.finalCheckResult === FinalFileCheckResult.WARNING ? "_WARNING" : ""));
        }
    }

    function renderDetectionName(record: string) {
        return (
            <>
                {(record) ? record.split(',').map((value, index) =>
                    <div key={index}>{value}<br/></div>
                ) : null}
            </>
        )
    }

    function renderExtraFileCheckInfo(text: any, record: FileCheck) {
        const mimeExtraInfo = (
            <>
                {record.extraInfo?.mimeTypes && <SecurityScanFilled
                    title={intlMessage("package-detail.all-mime-types.title")}
                    onClick={() => props.onClickExtraFileCheckInfo?.(record.extraInfo)}
                />}
            </>
        );

        return (
            <>
                {(record.detectionEngine?.type === 'MIME') && mimeExtraInfo}

                {(record.linkToDetail) ? <a href={record.linkToDetail} target="_blank" rel="noreferrer"><LinkOutlined style={{marginRight: 8}}/></a> : ""}

                {renderFileCheckReport(text, record)}
            </>
        );
    }

    function renderFileCheckReport(text: any, fileCheck: FileCheck) {
        if ((fileCheck.detectionEngine.type === DetectionEngineType.CHECKPOINT_SANDBLAST || fileCheck.detectionEngine.type === DetectionEngineType.CHECKPOINT_SANDBLAST_CLOUD || fileCheck.detectionEngine.type === DetectionEngineType.FORTISANDBOX) && fileCheck.fileCheckReports.length > 0) {
            return (
                <SecurityScanFilled
                    title={intlMessage("package-detail.file-check.reports")}
                    onClick={() => props.onClickFileCheck?.(fileCheck)}/>
            );
        }
    }

    function folderSorter(a: File | Folder, b: File | Folder, property: string, sortOrder?: SortOrder) {
        const valueA = (a as any)[property];
        const valueB = (b as any)[property];

        if (sortOrder === "ascend" && a instanceof Folder && b instanceof File) {
            return -1;
        }

        if (sortOrder === "descend" && a instanceof Folder && b instanceof File) {
            return 1;
        }

        if (a instanceof File && b instanceof Folder) {
            return 1;
        }

        if (valueA < valueB) {
            return valueA > valueB ? 1 : -1;
        } else {
            return valueA > valueB ? 1 : 0;
        }
    }

    function renderItemName(name: string, fileOrFolder: File | Folder) {
        if (fileOrFolder instanceof File) {
            return renderFileName(name, fileOrFolder as File);
        }

        return renderFolderName(fileOrFolder as Folder);
    }

    function renderFolderName(folder: Folder) {
        if (folder.name === '/') {
            if (props.disableFolderNameLinks) {
                return visualizeStateIfNeeded(folder, intlMessage("root-folder"));
            } else if (props.onFolderNameClick !== undefined) {
                return visualizeStateIfNeeded(folder, <a onClick={e => props.onFolderNameClick?.(folder)}>{intlMessage("root-folder")}</a>);
            } else {
                return visualizeStateIfNeeded(folder, <Link to={`/packages/${props.packageId}`}>{intlMessage("root-folder")}</Link>);
            }
        }

        if (props.disableFolderNameLinks) {
            return visualizeStateIfNeeded(folder, folder.name);
        } else if (props.onFolderNameClick !== undefined) {
            return visualizeStateIfNeeded(folder, <a onClick={e => props.onFolderNameClick?.(folder)}>{folder.name}</a>);
        } else {
            return visualizeStateIfNeeded(folder, <Link to={`/packages/${props.packageId}/${folder.id}`}>{folder.name}</Link>);
        }
    }

    function renderFileName(name: string, file: File) {
        const filePath = `/download-file/${file.id}`;

        if (file.possibleActions?.includes(FileOrFolderAction.DOWNLOAD) && props.disableDownloadLinks !== true) {
            return (
                <>
                    <Space>
                        <a href={filePath} onClick={e => {
                            e.preventDefault();

                            props.onClickDownload?.(filePath, file.id!, DownloadObjectType.FILE);
                        }}>
                            {visualizeStateIfNeeded(file, file.name)}
                        </a>
                    </Space>

                    <br/>
                    {!props.hideFileFlags && renderFileFlags(file)}
                </>
            )
        } else {
            return (
                <>
                    <span>{visualizeStateIfNeeded(file, file.name)}</span>
                    <br/>
                    {!props.hideFileFlags && renderFileFlags(file)}
                </>
            )
        }
    }

    function renderState(text: string, fileOrFolder: File | Folder) {
        if (fileOrFolder instanceof Folder) {
            return;
        }

        switch (text) {
            case "CLEAN":
                if (fileOrFolder.flags?.includes(FileFlag.CDR) || fileOrFolder.originalFile) {
                    return intlMessage("FileState.CDR");
                } else {
                    return intlMessage("FileState.CLEAN");
                }
            case "UNCLEAN":
                return intlMessage("FileState.UNCLEAN");
            case "UPLOADING":
                return intlMessage("FileState.UPLOADING");
            case "UPLOAD_CANCELED":
                return intlMessage("FileState.UPLOAD_CANCELED");
            case "WAITING":
                return intlMessage("FileState.WAITING");
            default:
                return intlMessage("FileState.UNKNOWN_STATE");
        }

    }

    function renderFileMimeType(mimeType: any) {
        return (
            <Text ellipsis={true} style={{maxWidth: "150px"}}>
                {mimeType}
            </Text>
        );
    }

    function renderFileIntegrity(hash: string, fileOrFolder: File | Folder) {
        if (fileOrFolder instanceof Folder) {
            return;
        }

        let result: any;

        switch (fileOrFolder.integrityCheckResult) {
            case FileIntegrityResult.VALID:
                result = (<Tooltip
                    overlayInnerStyle={{textAlign: "left"}}

                    title={intlMessage("package-detail.file-integrity-check-result-tooltip", {
                        result: intlMessage("package-detail.file-integrity-check-result.VALID"),
                        checkedAt: fileOrFolder.integrityCheckDone ? FormatUtils.formatDate(fileOrFolder.integrityCheckDone) : ""
                    })}>
                    <CheckCircleFilled className={"color-green"}/>
                </Tooltip>);
                break;
            case FileIntegrityResult.INVALID:
                result = (<Tooltip
                    overlayInnerStyle={{textAlign: "left"}}
                    title={intlMessage("package-detail.file-integrity-check-result-tooltip", {
                        result: intlMessage("package-detail.file-integrity-check-result.INVALID"),
                        checkedAt: fileOrFolder.integrityCheckDone ? FormatUtils.formatDate(fileOrFolder.integrityCheckDone) : ""
                    })}>
                    <ExclamationCircleFilled className={"color-red"}/>
                </Tooltip>);
                break;
            case FileIntegrityResult.INACCESSIBLE:
                result = (<Tooltip
                    overlayInnerStyle={{textAlign: "left"}}
                    title={intlMessage("package-detail.file-integrity-check-result-tooltip", {
                        result: intlMessage("package-detail.file-integrity-check-result.INACCESSIBLE"),
                        checkedAt: fileOrFolder.integrityCheckDone ? FormatUtils.formatDate(fileOrFolder.integrityCheckDone) : ""
                    })}>
                    <ExclamationCircleFilled className={"color-orange"}/>
                </Tooltip>);
                break;
            case FileIntegrityResult.UNKNOWN:
            default:
                result = (<Tooltip
                    overlayInnerStyle={{textAlign: "left"}}
                    title={intlMessage("package-detail.file-integrity-check-result-tooltip", {
                        result: intlMessage("package-detail.file-integrity-check-result.UNKNOWN"),
                        checkedAt: fileOrFolder.integrityCheckDone ? FormatUtils.formatDate(fileOrFolder.integrityCheckDone) : ""
                    })}>
                    <QuestionCircleFilled className={"color-gray"}/>
                </Tooltip>);
                break;
        }

        const runIntegrityCheck = !fileOrFolder.integrityCheckStarted ? <OkLeftPopconfirm
            title={intlMessage("packages-detail.confirm-run-integrity-check-on-file", {fileName: fileOrFolder.name ? fileOrFolder.name : fileOrFolder.id})}
            onConfirm={() => props.onRunIntegrityCheckOnFile?.(fileOrFolder)}
            okText={intlMessage("common.yes")}
            cancelText={intlMessage("common.no")}>
            <a className={"button"} title={intlMessage("packages-detail.run-integrity-check-on-file")}>
                <SyncOutlined/>
            </a>
        </OkLeftPopconfirm> : <a className={"button"}><SyncOutlined spin={true}/></a>;

        if (fileOrFolder.hash) {
            return (
                <>
                    <Space>
                        {fileOrFolder.integrityCheckDone && <>{result}</>}

                        {!fileOrFolder.integrityCheckDone && <Tooltip
                            overlayInnerStyle={{textAlign: "left"}}
                            title={intlMessage("package-detail.file-integrity-check-result-tooltip", {
                                result: intlMessage("package-detail.file-integrity-check-result.UNKNOWN"),
                                checkedAt: fileOrFolder.integrityCheckDone ? FormatUtils.formatDate(fileOrFolder.integrityCheckDone) : ""
                            })}>
                            <QuestionCircleFilled className={"color-gray"}/>
                        </Tooltip>}

                        {props.integrityCheckAllowed && runIntegrityCheck}
                    </Space>
                    <br/>
                    <span className={"file-hash"}>
                        SHA256:&nbsp;
                        <CopyToClipboard text={fileOrFolder.hash!} onCopy={() => message.info(intlMessage("package-detail.copied-to-clipboard"))}>
                            <a title={intlMessage("package-detail.copy-link-title")} className={"copy-link copy-link-hash"}>
                                {fileOrFolder.hash}
                            </a>
                        </CopyToClipboard>
                    </span>
                </>
            );
        }
    }

    function renderUploadedBy(uploadedBy: User) {
        return uploadedBy ? uploadedBy.email : "";
    }

    function renderUploadedOrCreated(record: any, fileOrFolder: File | Folder) {
        if (fileOrFolder instanceof Folder) {
            return FormatUtils.formatDate((fileOrFolder as Folder).created);
        }

        return FormatUtils.formatDate((fileOrFolder as File).uploaded);
    }

    function renderSize(size: number) {
        return FormatUtils.formatBytes(size);
    }

    function renderDownloadCounter(counter?: DownloadCounter) {
        if (counter === undefined) {
            return;
        }

        return (
            <>
                <Tooltip
                    overlayInnerStyle={{textAlign: "center"}}
                    title={intlMessage("package-detail.download-count-tooltip", {
                        anonymousTotal: counter.anonymousUsersTotal,
                        registeredUsersTotal: counter.registeredUsersTotal,
                        adminsTotal: counter.adminsTotal
                    })}>

                    {counter.anonymousUsersTotal} / {counter.registeredUsersTotal}

                    {counter.adminsTotal > 0 && <span> / {counter.adminsTotal}</span>}

                </Tooltip>
            </>
        );
    }

    function packageFileDownloadCountSorter(a: File | Folder, b: File | Folder) {
        if (a instanceof Folder || b instanceof Folder) {
            return 0;
        }

        const prevUserTotal = (a.downloadCounter?.anonymousUsersTotal ?? 0) + (a.downloadCounter?.registeredUsersTotal ?? 0);
        const nextUserTotal = (b.downloadCounter?.anonymousUsersTotal ?? 0) + (b.downloadCounter?.registeredUsersTotal ?? 0);

        if (prevUserTotal > nextUserTotal) {
            return 1;
        } else if (prevUserTotal < nextUserTotal) {
            return -1;
        } else {
            return 0;
        }
    }

    function renderItemActions(fileOrFolder: File | Folder) {
        if (fileOrFolder instanceof File) {
            return renderFileActions(fileOrFolder as File);
        }

        return renderFolderActions(fileOrFolder as Folder);
    }

    function renderFolderActions(folder: Folder) {
        const menuItems: MenuItemType[] = [];

        if (folder.possibleActions?.includes(FileOrFolderAction.RENAME_OR_MOVE)) {
            menuItems.push({
                key: 'rename',
                label: <Button type="text" onClick={() => props.onEditFolder?.(folder)}>
                    <EditOutlined/>
                    {intlMessage("packages-detail.edit-filename")}
                </Button>
            });

            menuItems.push({
                key: 'move',
                label: <Button type="text" onClick={() => props.onMove?.([folder])}>
                    <DeliveredProcedureOutlined/>
                    {intlMessage("common.move")}
                </Button>
            });
        }

        if (folder.possibleActions?.includes(FileOrFolderAction.DELETE)) {
            menuItems.push({
                key: 'delete',
                label: <Popconfirm
                    title={intlMessage("package-folder-delete.confirm-delete", {name: folder.name})}
                    onConfirm={() => props.onDeleteFolderConfirm?.(folder)}>
                    <Button type="text">
                        <DeleteFilled/>
                        {intlMessage("common.delete")}
                    </Button>
                </Popconfirm>
            })
        }

        if (menuItems.length > 0) {
            const menu: MenuProps = {
                className: "more-actions",
                items: menuItems
            };

            return (
                <Dropdown menu={menu} trigger={['click']}>
                    <a className="ant-dropdown-link ant-btn ant-btn-icon-only ant-btn-default" title={intlMessage("common.actions")}>
                        <BarsOutlined style={{verticalAlign: 'middle'}}/>
                    </a>
                </Dropdown>
            );
        }
    }

    function renderMassActions() {

        const menuItems: MenuItemType[] = [];

        const actions = (selectedRowKeys.length > 0)
            ? selectedRowKeys.reduce((accumulator, value) => _.union(accumulator, value.possibleActions), [] as FileOrFolderAction[])
            : [];

        if (props.massActions?.includes(MassActions.MOVE) && actions.includes(FileOrFolderAction.RENAME_OR_MOVE)) {
            menuItems.push({
                key: 'move',
                label: <Button type="text" onClick={() => props.onMove?.(selectedRowKeys).then(() => setSelectedRowKeys([]))}>
                    <DeliveredProcedureOutlined/>{intlMessage("common.move")}
                </Button>
            });
        }

        if (props.massActions?.includes(MassActions.DOWNLOAD_AS_ZIP) && actions.includes(FileOrFolderAction.DOWNLOAD)) {
            menuItems.push({
                key: 'download-as-zip',
                label: <Button type="text" onClick={() => props.onDownloadAsZip?.(selectedRowKeys).then(() => setSelectedRowKeys([]))}>
                    <FileZipOutlined/>{intlMessage("download-as-zip")}
                </Button>
            });
        }

        if (props.massActions?.includes(MassActions.DELETE) && actions.includes(FileOrFolderAction.DELETE)) {
            menuItems.push({
                key: 'delete',
                label: <CheckboxPopConfirm
                    title={intlMessage("confirm-mass-file-delete")}
                    onConfirm={(deleteSafeCopy) => props.onMassDeleteConfirm?.(selectedRowKeys, deleteSafeCopy || false).then(() => setSelectedRowKeys([]))}
                    checkboxDefaultValue={false}
                    checkboxLabel={intlMessage("package-detail.delete-safe-copy")}
                    checkboxEnabled={true}
                >
                    <Button type="text">
                        <DeleteFilled/>
                        {intlMessage("common.delete")}
                    </Button>
                </CheckboxPopConfirm>
            });
        }

        const menu: MenuProps = {
            className: "more-actions",
            items: menuItems
        };

        return (
            <Dropdown menu={menu} placement="bottomLeft" arrow={true} trigger={['click']} disabled={selectedRowKeys.length < 1}>
                <Button style={{marginBottom: 10}}>{intlMessage("common.mass-actions")} {selectedRowKeys.length > 0 ? <span className={"count"}>{intlMessage("common.selected", {count: selectedRowKeys.length})}</span> : ""}</Button>
            </Dropdown>
        );
    }

    function renderFileActions(file: File) {
        const menuItems: MenuItemType[] = [];

        if (file.possibleActions?.includes(FileOrFolderAction.RENAME_OR_MOVE)) {
            menuItems.push({
                key: 'rename',
                label: <Button type="text" onClick={() => props.onEditFile?.(file)}>
                    <EditOutlined/>
                    {intlMessage("packages-detail.edit-filename")}
                </Button>
            })

            menuItems.push({
                key: 'move',
                label: <Button type="text" onClick={() => props.onMove?.([file])}>
                    <DeliveredProcedureOutlined/>
                    {intlMessage("common.move")}
                </Button>
            })
        }

        if (file.possibleActions?.includes(FileOrFolderAction.DELETE)) {
            menuItems.push({
                key: 'delete',
                label: <CheckboxPopConfirm
                    title={intlMessage("packages-inbox-outbox.confirm-file-delete", {fileName: file.name})}
                    onConfirm={(deleteSafeCopy) => props.onDeleteFileConfirm?.(file, deleteSafeCopy || false)}
                    checkboxDefaultValue={false}
                    checkboxLabel={intlMessage("delete-safe-copy")}
                    checkboxEnabled={file.cdrCount! > 0}>
                    <Button type="text">
                        <DeleteFilled/>
                        {intlMessage("common.delete")}
                    </Button>
                </CheckboxPopConfirm>
            })
        }

        if (menuItems.length > 0) {
            const menu: MenuProps = {
                className: "more-actions",
                items: menuItems
            };

            return (
                <Dropdown menu={menu} trigger={['click']}>
                    <a className="ant-dropdown-link ant-btn ant-btn-icon-only ant-btn-default" title={intlMessage("common.actions")}>
                        <BarsOutlined style={{verticalAlign: 'middle'}}/>
                    </a>
                </Dropdown>
            );
        }
    }

    function renderFileFlags(file: File) {
        return (
            <div className={"file-flags"}>
                {file.flags?.map((flag) => {
                    const key = "" + file.id + flag;

                    switch (flag) {
                        case FileFlag.OVERSIZE:
                            return <ColumnHeightOutlined key={key} className={"danger"} title={intlMessage("package-detail.file.flags.OVERSIZE")}/>
                        case FileFlag.ENCRYPTED_CONTENT:
                            return <FileUnknownFilled key={key} className={"danger"} title={intlMessage("package-detail.file.flags.ENCRYPTED_CONTENT")}/>
                        case FileFlag.DLP_MATCHED:
                            return;
                        default:
                            return;
                    }
                })}

                {file.cdrFiles && file.cdrFiles.length > 0 && file.possibleActions?.includes(FileOrFolderAction.DOWNLOAD) &&
                    (
                        (props.aPackage?.workflowState === PackageWorkflowState.ACTIVE || props.aPackage?.workflowState === PackageWorkflowState.DELETED) && !file.cdrFiles[0].deleted
                            ? <a href={`/download-file/${file.cdrFiles[0].id}`}
                                 onClick={e => {
                                     e.preventDefault();

                                     props.onClickDownload?.(`/download-file/${file.cdrFiles![0].id}`, file.id!, DownloadObjectType.FILE);
                                 }}>
                                <FilePdfFilled className={"info"} title={intlMessage("package-detail.list.flags.CDR")}/>
                            </a>
                            : <FilePdfFilled className={"info"} title={intlMessage("package-detail.list.flags.CDR")}/>
                    )
                }
            </div>
        );
    }

    function onSelect(item: (File | Folder)) {
        if (selectedRowKeys.includes(item)) {
            setSelectedRowKeys(prevState => {
                    const tmpList = prevState.filter(value => value !== item);
                    manageSelected(tmpList);
                    return tmpList;
                }
            );
        } else {
            setSelectedRowKeys(prevState => {
                const tmpList = prevState.concat(item);
                manageSelected(tmpList);
                return tmpList;
            });
        }
    }

    function onSelectAll(event: any) {
        const isChecked = (event.target as HTMLInputElement).checked;

        if (!props.items) {
            return;
        }

        if (isChecked) {
            setSelectedRowKeys(() => {
                manageSelected(actionableItems);

                return actionableItems;
            })
        } else {
            setSelectedRowKeys(() => {
                manageSelected([]);

                return [];
            });
        }
    }

    function manageSelected(list: (File | Folder)[]) {

        if (onSelectTimer.current) {
            clearInterval(onSelectTimer.current);
        }
        // bez timeoutu se muzou objevovat chyby, ze nejde prekreslit nadrazena komponenta pro soubeh setState
        onSelectTimer.current = setInterval(() => {
            /*
            if (typeof props.selectedCallback === 'function') {
                props.selectedCallback(list);
            }
            */
            clearInterval(onSelectTimer.current);
        }, 200);
    }

}

export default FolderContent;
